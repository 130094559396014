import React from "react";
import { story_data } from "../../data/data";

function Story () {
  const Content = ({ item }) => {
    if (item.type == "title") {
      return(
        <div className="home-title-container">
          <h3 className="story-title">{item.content}</h3>
        </div>
      )
    }
    else {
      return(
        <p className="home-about-body">{item.content}</p>
      )
    }
  }

  return (
    <div className="home-header">
      <h1>Our Story</h1>
      <div className="home-ctn1">
        <div>
          {
            story_data.map((item, i) => (
              <Content item = {story_data[i]} />
            ))
          }  
        </div>
      </div>
    </div>
  );
}

export default Story;

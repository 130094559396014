import React from "react";
import google from "../../Assets/google.png"
import app_store from "../../Assets/app_store.png"
import { intro_data} from "../../data/data";

function Home() {

  const Detailed = ({item}) => {
    return(
      <div className="project-card-container">
        <div className="portfolio-card" >
          <div className="portfolio-text-container">
            <h2 className="portfolio-title">{item.title}</h2>
            <text className="portfolio-description">{item.description}</text>
          </div>
          <div className="portfolio-img-container">
            <img src={item.img} className="portfolio-img"/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className="home-header" >
      <div className="home-banner-container">
        <h1 className="home-banner-text">Find what great readers read</h1>
      </div>
      <div className="home-main-container">
        <div className="store-img-container">
          <img src={app_store} className="store-img" onClick={() => window.open("https://apps.apple.com/us/app/linking-book/id1624364277")}/>
          {/* <img src={google} className="store-img" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.thoughtzone.thoughtzone")}/> */}
        </div>
        <div className="home-info-container">
          <text style={{alignSelf: "center"}}> email / password for tester: 10@gmail.com / 1234567890</text>
          <div className="home-title-container">
            <h3>Better information source</h3>
            <h6> based on the trust of people</h6>
          </div>
          <p className="home-about-body">
            Linking Book believe the best information source is based on people’s trust, but not on algorithm.
            <br/>
            <br/>
            If there’s a place built for people to share what they read, and readers can read those content, we believe that will be beautiful.
          </p>
        </div>
      </div>
      <div className="portfolio">
        {
          intro_data.map((item, i) => (
            <Detailed item={intro_data[i]} />
          ))
        }
      </div>
    </section>
  );
}

export default Home;

import screen1 from "../Assets/screen1.png"
import screen2 from "../Assets/screen2.png"


export const intro_data = [
        {
                title: "All in one place",
                description:"Articles on the Linking-Book cross all platform, and people from different backgrounds share their opinions." ,
                img: screen1
        },
        {
                title: "Don’t miss out",
                description:"Follow readers you like and keep informed.",
                img: screen2
        },
]

export const story_data = [
        {
                type: "title",
                content: "I felt pain when I shared opinions on existing platforms"
        },
        {
                type: "content",
                content: "IG, the primary platform my friends and I use, lets people share their life highlight reels. However, when I shared my opinions, such as takeaways from books, they didn’t get much feedback."
        },
        {
                type: "content",
                content: "One day after reading “Factfulness” by Hans Rosling, I shared the book, but people didn’t respond to me as much as usual, because people didn’t expect to see this on IG."
        },
        {
                type: "title",
                content: "Better passive information source"
        },
        {
                type: "content",
                content: "One day I posted a story that I wanted to give away books, and surprisingly, many people responded to me. I realized that people, including me, are still interested in what others are reading, but exciting platforms are not suitable."
        },
        {
                type: "content",
                content: "I started to think about creating a place that people can share their opinions, what they read, and what inspires them."
        },
        {
                type: "title",
                content: "Misinformation problem"
        },
        {
                type: "content",
                content: "Misinformation has been a big problem for a long time. However, we can build a community to get information based on the people we trust. I think that will be very beautiful."
        },
        {
                type: "content",
                content: "Thank you for reading this article instead of consuming other social media."
        },
        {
                type: "content",
                content: "Sincerely,"
        },
        {
                type: "content",
                content: "Andy"
        },
]